<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat  :loading="loadingstatus"
        loading-text="Loading... Please wait">
        <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn medium color="primary" @click="btnsearch" class="mr-1">
            <v-icon left>mdi-database-refresh</v-icon>LOAD DATA</v-btn
          >

<export-excel
            v-if="desserts.length > 0"
            :data="desserts"
            type="button"
            name="Complete Report"
          >
            <v-btn color="success"
              ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
            >
          </export-excel>
        </v-card-actions>
      <v-row>
      
          <!-- <v-select
            label="Select Company"
            v-model="SelCompany"
            :items="Company"
            item-text="company_name"
            item-value="company_id"
            dense
            outlined
            hide-details
          ></v-select> -->
        

        <!-- <v-col cols="12" md="3">
          <v-select
            label="Select Status"
            v-model="Selstatus"
            :items="Status"
            item-text="status_named"
            item-value="status_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col> -->

        <v-col cols="12" md="2">
         
        </v-col>

        <v-col cols="12" md="2">
       

          
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-1 mb-2" flat  v-if="desserts.length > 0">
      <v-data-table
        v-model="TDselected"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="application_id"
       
        style="border-radius: 0px"
      >
        <!-- @click="BtnApprove(index)" -->
        <templete v-slot:item="{ item }">
          <td class="tdalign">{{ item.s_no }}</td>
          <td class="tdalign">{{ item.app_id }}</td>
          <td class="tdalign">{{ item.fName }}</td>
          <td class="tdalign">{{ item.lName }}</td>
          <td class="tdalign">{{ item.fatherName }}</td>
          <td class="tdalign">{{ item.gender }}</td>
          <td class="tdalign">{{ item.dob }}</td>
          <td class="tdalign">{{ item.email }}</td>
          <td class="tdalign">{{ item.mobile }}</td>
          <td class="tdalign" style="white-space:nowrap;">{{ item.addLine1 }}</td>
          <td class="tdalign">{{ item.addLine2 }}</td>
          <td class="tdalign">{{ item.city }}</td>
          <td class="tdalign">{{ item.country }}</td>
          <td class="tdalign">{{ item.state }}</td>
          <td class="tdalign">{{ item.pinCode }}</td>
          <td class="tdalign">{{ item.nationality }}</td>
          <td class="tdalign">{{ item.idProofType }}</td>
          <td class="tdalign">{{ item.idProofNumber }}</td>
          <td class="tdalign">{{ item.print_text }}</td>
          <td class="tdalign">{{ item.jobtype_name }}</td>
          <td class="tdalign">{{ item.card_name }}</td>
          <td class="tdalign">{{ item.status_named }}</td>
          <td class="tdalign">{{ item.reason_name }}</td>
          <td class="tdalign">{{ item.qrCode }}</td>
          <td class="tdalign">{{ item.barcode }}</td>
          <td class="tdalign">{{ item.company_name }}</td>
          <td class="tdalign">{{ item.company_phone_no }}</td>
          <td class="tdalign">{{ item.vendor_name }}</td>
          <td class="tdalign">{{ item.vendor_contact_no }}</td>
          <td class="tdalign">{{ item.vendor_email }}</td>
          <td class="tdalign">{{ item.printDateLocal }}</td>

        </templete>

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar v-model="snackbar" shaped top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
//import ViewApplication from "../../components/ViewApplication.vue";
export default {
  data() {
    return {
      TDselected: [],
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: true,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",

      Selstatus: "8",

      SelstatusForUpade: "",

      SelReasonForUpdate: "",
      ResonForUpdate: [],

      SelCardTypeForUpdate: "",
      CardTypeForUpdate: [],
      strcheckatatus: "",

      SelCompany: "",
      Company: [],

      //companytype: [],
      companytype_id: [],
      ReportFor:"",

      headers: [
        {
          text: "Sr_no",
          align: "center",
          value: "s_no",
          class: "blue lighten-5",
        },

        {
          text: "App-ID",
          value: "app_id",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "First Name",
          value: "fName",
          align: "left",
          class: "blue lighten-5",
          
        },

        {
          text: "Last Name",
          value: "lName",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Father",
          value: "fatherName",
          align: "left",
          class: "blue lighten-5",
        },

         {
          text: "Gender",
          value: "gender",
          align: "left",
          class: "blue lighten-5",
        },

          {
          text: "Dob",
          value: "dob",
          align: "left",
          class: "blue lighten-5",
          
        },


{
          text: "Email",
          value: "email",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "AddLine 1",
          value: "addLine1",
          align: "left",
          class: "blue lighten-5",
        },

         {
          text: "AddLine 2",
          value: "addLine2",
          align: "left",
          class: "blue lighten-5",
        },


         {
          text: "City",
          value: "city",
          align: "left",
          class: "blue lighten-5",
        },


         {
          text: "Country",
          value: "country",
          align: "left",
          class: "blue lighten-5",
        },

          {
          text: "State",
          value: "state",
          align: "left",
          class: "blue lighten-5",
        },

          {
          text: "PinCode",
          value: "pinCode",
          align: "left",
          class: "blue lighten-5",
        },

          {
          text: "Nationality",
          value: "nationality",
          align: "left",
          class: "blue lighten-5",
        },

         {
          text: "IdProof",
          value: "idProofType",
          align: "left",
          class: "blue lighten-5",
        },

         {
          text: "IdProofNumber",
          value: "idProofNumber",
          align: "left",
          class: "blue lighten-5",
        },

         {
          text: "Stand",
          value: "print_text",
          align: "left",
          class: "blue lighten-5",
        },

   {
          text: "Jobtype",
          value: "jobtype_name",
          align: "left",
          class: "blue lighten-5",
        },

           {
          text: "Card",
          value: "card_name",
          align: "left",
          class: "blue lighten-5",
        },

 {
          text: "Status",
          value: "status_named",
          align: "left",
          class: "blue lighten-5",
        },

   {
          text: "Reason",
          value: "reason_name",
          align: "left",
          class: "blue lighten-5",
        },


   {
          text: "QR Code",
          value: "qrCode",
          align: "left",
          class: "blue lighten-5",
        },

         {
          text: "Barcode",
          value: "barcode",
          align: "left",
          class: "blue lighten-5",
        },

  {
          text: "Company",
          value: "company_name",
          align: "left",
          class: "blue lighten-5 tdalign",
        },

  {
          text: "PHNO",
          value: "company_phone_no",
          align: "center",
          class: "blue lighten-5",
        },

  {
          text: "Coordinator",
          value: "coordinator",
          align: "center",
          class: "blue lighten-5",
        },

        {
          text: "Vendor Contact No",
          value: "vendor_contact_no",
          align: "center",
          class: "blue lighten-5",
        },

        {
          text: "Vendor Email",
          value: "vendor_email",
          align: "center",
          class: "blue lighten-5",
        },
        {
          text: "Print Date",
          value: "printDateLocal",
          align: "center",
          class: "blue lighten-5",
        },


      
      ],
      desserts: [],
      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
   
    this.$store.commit("setPageTitle", "Complete Report");
    //this.$store.commit("setEventName", sessionStorage.display_event_name);
    //this.$store.commit("setVenueName", sessionStorage.display_venue_name);

    // this.bindStatus();
    // this.bindCartype(this.$store.state.eventInfo.EventId);
    // this.bindCompany(
    //   this.$store.state.eventInfo.EventId,
    //   this.$store.state.eventInfo.VenueId
    // );
    //  this.bindReson("1");
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    // watch: {
    //   chkselected: function () {
    //     // this.sheet=true;
    //     if (this.chkselected.length > 0) {
    //       alert("greater");
    //     }
    //   },
    // },

    chkall: function () {
     
    },

    

    btnsearch: function () {
     
      this.Reload();
     
    },

    bindCompany: async function (EventID, VenueID) {
      await this.$axios
        .get("vendor/GetByEventId/" + EventID + "/" + VenueID)
        .then((res) => {
          if (res.data.result.length > 0) {
            this.Company = res.data.result;
          }
        });
    },

    bindCartype: async function (event_id) {
      await this.$axios
        .get("Cards/GetByEventId/" + event_id)
        .then((res) => {
          this.CardTypeForUpdate = res.data.result;
        });
    },

    bindStatus: async function () {
      await this.$axios
        .get("Status")
        .then((res) => {
          this.Status = res.data.result;
          this.StatusForUpdate = res.data.result;
        });
    },

    Reload: async function () {
      this.loadingstatus = true;
      await this.$axios
        .get(
          "Report/CompleteAccrdReport/" + this.$store.state.eventInfo.EventId + "/" + this.$store.state.eventInfo.VenueId + "/" + this.$store.state.eventInfo.venuetype + "/" + this.$store.state.eventInfo.EventAdminID
        )
        .then((res) => {
          this.desserts = res.data.result;
          console.log(this.desserts);

          this.loadingstatus = false;
        })
        .catch()
        .finally();
    },
  },
};
</script>

<style  scoped>
.tdalign {
  text-align: left;
  white-space: nowrap !important;
  background-color: brown;
}
td{
  white-space: nowrap !important;
}
th{
  white-space: nowrap !important;
}
.v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    text-align: left;

    white-space: nowrap !important;
}
</style>>

